import React from 'react'

export default function Copy({ className = '' }) {
    return (
        <div className={className}>
            <svg width="14" height="14" viewBox="0 0 14 14" className='w-full h-full' fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.1289 3.5H5.05859C4.19781 3.5 3.5 4.19781 3.5 5.05859V11.1289C3.5 11.9897 4.19781 12.6875 5.05859 12.6875H11.1289C11.9897 12.6875 12.6875 11.9897 12.6875 11.1289V5.05859C12.6875 4.19781 11.9897 3.5 11.1289 3.5Z" stroke="#122523" strokeLinejoin="round" />
                <path d="M10.4863 3.5L10.5 2.84375C10.4988 2.43799 10.3371 2.04918 10.0502 1.76227C9.76332 1.47535 9.37451 1.31365 8.96875 1.3125H3.0625C2.59879 1.31387 2.15447 1.49869 1.82658 1.82658C1.49869 2.15447 1.31387 2.59879 1.3125 3.0625V8.96875C1.31365 9.37451 1.47535 9.76332 1.76227 10.0502C2.04918 10.3371 2.43799 10.4988 2.84375 10.5H3.5" stroke="#122523" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}
