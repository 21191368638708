import React from 'react'

export default function Email() {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5312 0.0625H3.46875C2.71479 0.0633065 1.99194 0.363174 1.45881 0.896306C0.925674 1.42944 0.625807 2.15229 0.625 2.90625V15.0938C0.625807 15.8477 0.925674 16.5706 1.45881 17.1037C1.99194 17.6368 2.71479 17.9367 3.46875 17.9375H20.5312C21.2852 17.9367 22.0081 17.6368 22.5412 17.1037C23.0743 16.5706 23.3742 15.8477 23.375 15.0938V2.90625C23.3742 2.15229 23.0743 1.42944 22.5412 0.896306C22.0081 0.363174 21.2852 0.0633065 20.5312 0.0625ZM19.8112 4.76637L12.4987 10.4539C12.3561 10.5647 12.1806 10.6249 12 10.6249C11.8194 10.6249 11.6439 10.5647 11.5013 10.4539L4.18883 4.76637C4.10291 4.70149 4.03075 4.62018 3.97653 4.52717C3.92231 4.43415 3.88712 4.33129 3.87299 4.22456C3.85887 4.11783 3.8661 4.00936 3.89426 3.90545C3.92243 3.80153 3.97096 3.70425 4.03704 3.61926C4.10313 3.53427 4.18544 3.46325 4.27921 3.41035C4.37297 3.35744 4.47632 3.3237 4.58324 3.31108C4.69016 3.29846 4.79852 3.30722 4.90202 3.33684C5.00553 3.36646 5.10212 3.41636 5.18617 3.48363L12 8.78316L18.8138 3.48363C18.9842 3.35496 19.1984 3.29853 19.4101 3.32654C19.6217 3.35455 19.8139 3.46474 19.9449 3.6333C20.076 3.80185 20.1354 4.01522 20.1104 4.22725C20.0853 4.43929 19.9779 4.63296 19.8112 4.76637Z" fill="#122523" />
        </svg>

    )
}
