import React from 'react'

export default function Phone() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3284 21.5001C16.4134 21.5001 15.1281 21.1691 13.2034 20.0938C10.8629 18.7813 9.05262 17.5696 6.72481 15.2479C4.48044 13.0049 3.38825 11.5527 1.85965 8.77115C0.13278 5.63052 0.427155 3.98427 0.756217 3.28068C1.14809 2.43974 1.72653 1.93677 2.47419 1.43755C2.89885 1.15932 3.34825 0.920812 3.81669 0.725053C3.86356 0.704897 3.90715 0.685678 3.94606 0.668335C4.17809 0.563803 4.52965 0.405835 4.97497 0.574585C5.27215 0.686147 5.53747 0.914428 5.95278 1.32458C6.8045 2.16458 7.9684 4.03537 8.39778 4.95412C8.68606 5.57333 8.87684 5.98208 8.87731 6.44052C8.87731 6.97724 8.60731 7.39115 8.27965 7.83787C8.21825 7.92177 8.15731 8.00193 8.09825 8.07974C7.74153 8.54849 7.66325 8.68396 7.71481 8.92584C7.81934 9.41193 8.59887 10.859 9.87997 12.1372C11.1611 13.4155 12.5664 14.1458 13.0543 14.2499C13.3065 14.3038 13.4448 14.2222 13.9286 13.8529C13.9979 13.7999 14.0692 13.7451 14.1437 13.6902C14.6434 13.3185 15.0381 13.0555 15.5622 13.0555H15.565C16.0211 13.0555 16.4115 13.2533 17.0584 13.5796C17.9022 14.0052 19.8292 15.1541 20.6743 16.0068C21.0854 16.4211 21.3147 16.6855 21.4267 16.9822C21.5954 17.429 21.4365 17.7791 21.3329 18.0135C21.3156 18.0524 21.2964 18.0951 21.2762 18.1424C21.0789 18.61 20.839 19.0585 20.5595 19.4821C20.0612 20.2274 19.5564 20.8044 18.7136 21.1968C18.2808 21.4015 17.8071 21.5052 17.3284 21.5001Z" fill="#122523" />
        </svg>

    )
}
