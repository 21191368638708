'use client';

import React from 'react'

export default function Navigation() {
    const navRef = React.useRef(null);
    React.useEffect(() => {
        if (!navRef.current) return;
        
        handleScroll();
        
		prevScrollpos = window.pageYOffset;
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
    }, [navRef]);
    
    let prevScrollpos = 0;

    const navClasses = ['not-scrolled'];
    const navClassesScrolled = ['scrolled'];

    const replaceClasses = (reference, classes, classesScrolled, isScrolled) => {
		if (reference.current !== null) {
            if (classes.length > 0 && classesScrolled.length > 0) {
                if (isScrolled) {
                    reference.current.classList.remove(...classes);
                    reference.current.classList.add(...classesScrolled);
                } else {
                    reference.current.classList.remove(...classesScrolled);
                    reference.current.classList.add(...classes);
                }
			}
		}
	}

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;

        if (currentScrollPos <= 5) {
            replaceClasses(navRef, navClasses, navClassesScrolled, false);
        } else {
            if (prevScrollpos > currentScrollPos) {
                replaceClasses(navRef, navClasses, navClassesScrolled, false);
            } else {
                replaceClasses(navRef, navClasses, navClassesScrolled, true);
            }
        }
        prevScrollpos = currentScrollPos;
    }

    return (
        <nav ref={navRef} style={{zIndex: 10}} className='fixed top-0 left-0 right-0 bg-merino border-b border-aztec navbar hidden md:block'>
            <div className='container mx-auto'>
                <div className='grid grid-cols-2 border-b-1 relative'>
                    <ul className='col-span-2 flex flex-col gap-y-small items-center md:flex-row gap-x-large justify-center'>
                        <li><a href='/' className='underline block p-small'>Home</a></li>
                        <li><a href='#werkwijze' className='underline block p-small'>Werkwijze</a></li>
                        <li><a href='#diensten' className='underline block p-small'>Diensten</a></li>
                        <li><a href='#contact' className='underline block p-small'>Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
