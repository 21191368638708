'use client';

import React from 'react'
import Phone from '@/components/footer/icons/phone'
import Email from '@/components/footer/icons/email'
import Copy from '@/components/footer/icons/copy'

export default function Footer() {
    function copyToClipboard(text) {
        if (typeof window === 'undefined') return;
        if (typeof navigator === 'undefined') return;
        if (!navigator.clipboard) return;
        
        navigator.clipboard.writeText(text).then(() => {
            alert(`Tekst: ${text} is gekopieerd naar het klembord`);
        });
    }

    return (
        <footer className='container mx-auto grid grid-cols-1 mt-small gap-y-section-mobile py-section-mobile md:pt-section md:pb-section-mobile'>
            <div className='col-span-1'>
                <h2 className=''>&ldquo;Digitale inclusie begint bij jou. Samen creëren we een online ervaring waar niemand buiten de boot valt.&rdquo;</h2>
                {/* <div className='flex gap-x-4 mt-4'>
                    <button className='button-eden'>Ik wil digitale inclusie!</button>
                </div> */}
            </div>
            <div className='col-span-1 border-t border-aztec'></div>
            <nav className='col-span-1 grid grid-cols-2 gap-y-small'>
                <div className='col-span-2 md:col-span-1'>
                    <p className='font-bold'>Link</p>
                    <ul className='flex flex-col gap-y-2'>
                        <li><a href='/' className='underline'>Home</a></li>
                        <li><a href='#diensten' className='underline'>WCAG-Diensten</a></li>
                        <li><a href='#contact' className='underline'>Contact</a></li>
                    </ul>
                </div>
                <div className='col-span-2 md:col-span-1'>
                    <p className='font-bold'>Contact</p>
                    <ul className='flex flex-col gap-y-2'>
                        <li><a href='/'>Webbeukers</a></li>
                        <li><a title='Adres Webbeukers' target='_blank' href='https://maps.app.goo.gl/MZGGwoph2yUn3A3o6' className='underline'>Anthonie fokkerstraat 39-G<br />3772MP Barneveld</a></li>
                        <li className='flex'>
                            <span className='mr-3'>
                                <Phone />
                            </span>
                            <a title='Telefoonnummer Webbeukers' href='tel:+31342226486'className="underline">0342 22 64 86</a>
                            <button type='button' onClick={() => copyToClipboard('0342 22 64 86')} className='ml-1 pointer px-2' title='Telefoonnummer kopiëren'>
                                <Copy className="w-5 h-5" />
                            </button>
                        </li>
                        <li className='flex'>
                            <span className='mr-3'>
                                <Email />
                            </span>
                            <a title='Email adres Webbeukers' href='mailto:hello@webbeukers.nl'className="underline">hello@webbeukers.nl</a>
                            <button type='button' onClick={() => copyToClipboard('hello@webbeukers.nl')} className='ml-1 pointer px-2' title='Email adres kopiëren'>
                                <Copy className="w-5 h-5 p2" />
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className='col-span-1 '>
                <p className='text-center'>© WCAG Websites { new Date().getFullYear() }</p>
            </div>
        </footer>
    )
}
